<template>
  <div id="employeeVacation" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">VACACIONES DE EMPLEADO</v-row>
      <v-container fluid class="addForms" style="margin-top: 2% !important">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formulariosTargetL"
          >
            <v-row class="mt-n5">
              <div class="form-group">
                <label for="diasVacaciones">Días de Vacaciones</label>
                <input
                  type="text"
                  name="diasVacaciones"
                  id="diasVacaciones"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasVacaciones"
                  required
                  disabled
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="diasDisfrutados">Días disfrutados</label>
                <input
                  type="text"
                  name="diasDisfrutados"
                  id="diasDisfrutados"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasDisfrutados"
                  required
                  disabled
                />
              </div>
              <div class="form-group">
                <label for="diasDisponibles">Días disponibles</label>
                <input
                  type="text"
                  name="diasDisponibles"
                  id="diasDisponibles"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="diasDisponibles"
                  required
                  disabled
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarilloDerecho" @click="regresar()">
                Regresar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="base text-center">
        <v-row
          no-gutters
          class="datos pa-0"
          style="height: auto !important; width: 100% !important"
        >
          <v-card
            style="width: 100%; background: rgb(199, 195, 199) !important"
          >
            <v-data-table
              :headers="headers"
              :items="vacations"
              :items-per-page="5"
              :search="search"
              class="elevation-2 text--dark"
              loading-text="Cargando datos... Por favor espere"
              :no-results-text="'No se encontraron incidencias'"
              :no-data-text="'No hay datos'"
              :footer-props="{
                'items-per-page-text': 'Fechas por página',
                'items-per-page': [5, 10, 15],
                'items-per-page-all-text': 'Todas',
                'items-per-page-options': [5, 10],
                'loading-text': 'Obteniendo datos... Por favor espere',
                'no-data-text': 'No hay datos...',
                'no-results-text': 'No se encontraron incidencias',
              }"
            >
              <template v-slot:no-data>
                <v-alert :value="true" color="#ffffff" icon="warning">
                  <p style="color: black">No hay datos en sistema ...</p>
                </v-alert>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{ selectedRow: item === selectedItem }"
                  >
                    <td class="text-sm-center">{{ item.id }}</td>
                    <td class="text-sm-center">
                      {{ item.empleadoSolicita.nombre }}
                      {{ item.empleadoSolicita.apellidoPaterno }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaSolicitud.replace("T", " ") }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaInicioVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaFinVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      <span v-if="item.autorizacionJefe">{{
                        item.jefeAutoriza.nombre +
                        " " +
                        item.jefeAutoriza.apellidoPaterno
                      }}</span>
                    </td>

                    <td class="text-sm-center">
                      {{ item.estatus.descripcionEstatus }}
                    </td>
                    <td class="justify-center layout px-0">
                      <v-btn
                        icon
                        color="green"
                        @click="confirmacion(item)"
                        class="mt-1 text-white"
                        v-if="item.estatusId == 2"
                      >
                        <v-icon small>fas fa-check-circle</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="red"
                        @click="editItem(item)"
                        class="mt-1 text-white"
                        v-if="item.estatusId == 2"
                      >
                        <v-icon small>fas fa-ban</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardarAutorizacion()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Editar item-->
    <v-dialog v-model="edit" max-width="420" scrollable>
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Datos de solicitud</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="id">Id de Empleado</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="text"
                name="id"
                id="id"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.empleadoSolicitaId"
                disabled
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="nombre">Nombre</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                :value="
                  dataUser.empleadoSolicita.nombre +
                  ' ' +
                  dataUser.empleadoSolicita.apellidoPaterno
                "
                disabled
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="solicitud">Fecha de Solicitud</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="text"
                name="solicitud"
                id="solicitud"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.fechaSolicitud"
                required
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="inicio">Inicio de vacaciones</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="date"
                name="inicio"
                id="inicio"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.fechaInicioVacaciones"
                required
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="fin">Fin de vacaciones</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="date"
                name="fin"
                id="fin"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.fechaFinVacaciones"
                required
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="dias">Días solicitados</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <input
                type="text"
                name="dias"
                id="dias"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.diasSolicitados"
                required
                disabled
                @keypress="isNumber($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="dias">Actividades pendientes</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <textarea
                type="textarea"
                name="dias"
                id="dias"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="dataUser.actividadesPendientes"
                disabled
              ></textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="jefe">Autorización de Jefe</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <v-checkbox
                v-model="dataUser.autorizacionJefe"
                class="mt-n3"
                color="orange"
                :label="`${dataUser.autorizacionJefe ? 'Sí' : 'No'}`"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="rh">Autorización de RH</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <v-checkbox
                v-model="autorizacionRH"
                class="mt-n3"
                color="orange"
                :label="`${autorizacionRH ? 'Sí' : 'No'}`"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="denegacion">Motivo de denegación</label></v-col
            ><v-col cols="12" sm="7" md="7" lg="7">
              <textarea
                type="textarea"
                name="denegacion"
                id="denegacion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="denegacion"
                autofocus
              ></textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="rechazo()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      edit: false,
      search: "",
      enterprises: [],
      enterprise: 0,
      dialog: false,
      selectedItem: null,
      message: "",
      //dialogs de empleado
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      autorizo: false,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Fecha solicitud",
          align: "center",
          value: "fechaSolicitud",
          sortable: false,
        },
        {
          text: "Fecha inicio vacaciones",
          align: "left",
          value: "fechaInicioVacaciones",
          sortable: false,
        },
        {
          text: "Fecha fin vacaciones",
          align: "center",
          value: "fechaFinVacaciones",
          sortable: false,
        },
        {
          text: "Autorizo",
          align: "center",
          value: "autorizo",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus",
        },
        {
          text: "Autorización",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      empleado: 0,
      denegacion: "",
      vacations: [],
      diasVacaciones: 0,
      diasDisfrutados: 0,
      diasDisponibles: 0,
      dataUser: {
        empleadoSolicitaId: 11,
        empleadoSolicita: { nombre: "", apellidoPaterno: "" },
        id: 1,
        nombre: "Paola X Y",
        fechaSolicitud: "2020/05/01",
        fechaInicioVacaciones: "2020/05/15",
        fechaFinVacaciones: "2020/05/18",
        diasSolicitados: 3,
        actividadesPendientes: "",
        autorizacionJefe: false,
        autorizacionRH: true,
        estatus: "OK",
      },
      autorizacionRH: false,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    editItem(item) {
      this.respuesta = "ok";
      this.dataUser = item;
      this.dataUser.fechaSolicitud = this.dataUser.fechaSolicitud;
      this.dataUser.fechaInicioVacaciones = this.dataUser.fechaInicioVacaciones.substr(
        0,
        10
      );
      this.dataUser.fechaFinVacaciones = this.dataUser.fechaFinVacaciones.substr(
        0,
        10
      );
      this.edit = true;
    },
    guardarAutorizacion() {
      if (this.autorizo == true) {
        console.log("aqui api para autorizar solicitud");
        //this.id es el id del empleado que solicita
        this.dataUser.autorizacionRH = true;
        this.guardar();
        console.log(this.id);
        //al final se debe de pasar this.autorizo=false;
        /* this.message = "SOLICITUD DE VACACIONES";
        this.respuesta = "Se han autorizado las vacaciones"; */
        this.respuesta = "Se han autorizado las vacaciones";
        this.advice = false;
        this.empleado = this.id;
        this.recuperar();
        this.confirmation = true;
      } else {
        this.advice = false;
        console.log("aqui api para RECHAZAR solicitud");
        //this.id es el id del empleado que SE RECHAZAN VACACIONES
        console.log(this.id);
        //al final se debe de pasar this.autorizo=false;
        //this.denegacion es el motivo
        this.guardar();
        this.message = "SOLICITUD DE VACACIONES";
        this.respuesta = "Se han rechazado las vacaciones";
        this.empleado = this.id;
        this.recuperar();
        this.confirmation = true;
      }
    },
    confirmacion(item) {
      this.message = "Autorización de vacaciones";
      this.id = item.empleadoSolicitaId;
      this.respuesta =
        "Esta seguro de autorizar las vaciones para el empleado " +
        item.empleadoSolicita.nombre +
        " " +
        item.empleadoSolicita.apellidoPaterno;
      this.dataUser = item;
      this.autorizo = true;
      this.advice = true;
    },
    aux() {
      this.edit = false;
      this.denegacion = "";
      this.message = "";
      this.respuesta = "";
      this.autorizo = false;
      this.confirmation = false;
    },
    rechazo() {
      //this.autorizacionRH valor en falso de rh para pasar a api
      //this.dataUser son los datos del usuario
      //denegacion es el campo del texto del motivo
      this.errors = [];
      if (this.denegacion == " " || this.denegacion == "") {
        this.errors.push("Se debe especificar motivo por el cual denego.");
      }
      if (this.errors.length == 0) {
        this.message = "Rechazo de vacaciones";
        this.respuesta =
          "Esta seguro de rechazar las vacaciones solicitadas del empleado " +
          this.dataUser.empleadoSolicita.nombre +
          " " +
          this.dataUser.empleadoSolicita.apellidoPaterno;
        this.dataUser.motivoDenegaSolicitud = this.denegacion;
        this.dataUser.estatusId = 4;
        this.edit = false;
        this.advice = true;
      } else {
        this.message = "Denegación de vacaciones";
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    regresar() {
      this.$router.push("/directory");
    },
    cancelar() {
      this.id = 0;
      this.denegacion = "";
      this.autorizacionRH = false;
      this.autorizo = false;
      this.advice = false;
    },
    guardar() {
      this.advice = false;
      // console.log("vacaciones", this.dataUser);
      this.show = true;
      axios
        .put(Server + "/vacaciones/" + this.dataUser.id, this.dataUser, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.message = "Confirmación";
          this.respuesta = "Las vacaciones fueron actualizadas con éxito";
          this.show = false;
          this.confirmation = true;
          this.recuperar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.diasVacaciones = 0;
            this.diasDisfrutados = 0;
            this.diasDisponibles = 0;
            this.message = "Aviso";
            this.respuesta = "Las vacaciones no fueron almacenadas.";
            this.confirmation = true;
          }
        });
    },
    calcularVacaciones() {
      this.empleado = localStorage.empleadoId;
      localStorage.empleadoId = "";
      this.show = true;
      axios
        .get(Server + "/vacaciones/por-empleado/" + this.empleado, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          (this.diasVacaciones = response.data.diasCorresponde),
            (this.diasDisfrutados = response.data.diasDisfrutados),
            (this.diasDisponibles = response.data.diasPorDisfrutar);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/vacaciones/solicitudes-empleado/" + this.empleado, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.vacations = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.calcularVacaciones();
    this.recuperar();
  },
};
</script>